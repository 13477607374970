<template>
  <app-header/>

  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>

  <app-player />

  <auth-modal/>
</template>

<script setup>
import AppHeader from '@/components/Header.vue';
import AuthModal from '@/components/Auth.vue';
import AppPlayer from '@/components/Player.vue';

import { useStore } from "vuex";

const store = useStore()

store.dispatch('init_login');
</script>

<style lang="scss">
@import "assets/main";
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Neucha&display=swap');
</style>
