import { Howl } from 'howler';
import helper from '@/utils/helper';
import { songsCollection, auth, commentsCollection, storage } from '@/includes/firebase';
import { maxFileSizeMb } from "@/utils/constants";

export default {
  state: {
    currentSong: {},
    currentPlaylist: [],
    sound: {},
    seek: '00:00',
    duration: '00:00',
    playerProgress: '0%',
    isListFiltered: false,
    isListShuffled: false,
    currentVolume: 0.4,
    snapshots: null,
    maxPerPage: 20,
    songs: [],
    manageSongs: [],
    tempList: [],
    comments: [],
    uploads: []
  },

  getters: {
    isPlaying: (state) => {
      if (state.sound.playing) {
        return state.sound.playing();
      }
      return false;
    },
    currentSong: (state) => state.currentSong,
    isListFiltered: (state) => state.isListFiltered,
    isListShuffled: (state) => state.isListShuffled,
    songs: (state) => state.songs,
    snapshot: (state) => state.snapshot,
    manageSongs: (state) => state.manageSongs,
    comments: (state) => state.comments,
    seek: (state) => state.seek,
    duration: (state) => state.duration,
    playerProgress: (state) => state.playerProgress,
    currentPlaylist: (state) => state.currentPlaylist,
    uploads: (state) => state.uploads,
  },

  mutations: {
    newSong(state, payload) {
      state.currentSong = payload;

      state.sound = new Howl({
        src: [payload.url],
        html5: true,
        volume: state.currentVolume,
      });
    },

    updatePosition(state) {
      state.seek = helper.formatTime(state.sound.seek());
      state.duration = helper.formatTime(state.sound.duration());
      state.playerProgress = `${(state.sound.seek() / state.sound.duration()) * 100}%`;
    },

    updateCurrentPlaylist(state, payload) {
      state.currentPlaylist = payload;
    },

    updateIsListFiltered(state) {
      state.isListFiltered = !state.isListFiltered;
    },

    updateIsListShuffled(state) {
      state.isListShuffled = !state.isListShuffled;
    },

    getCurrentSong(state) {
      if (JSON.parse(localStorage.getItem('currentSong'))) {
        state.currentSong = JSON.parse(localStorage.getItem('currentSong'));
      }
      return state.currentSong;
    },

    getCurrentPlaylist(state) {
      if (JSON.parse(localStorage.getItem('currentPlaylist'))) {
        state.currentPlaylist = JSON.parse(localStorage.getItem('currentPlaylist'));
      }
      return state.currentPlaylist;
    },

    getIsListFiltered(state) {
      if (JSON.parse(localStorage.getItem('isListFiltered'))) {
        state.isListFiltered = JSON.parse(localStorage.getItem('isListFiltered'));
      }
      return state.isListFiltered;
    },

    getIsListShuffled(state) {
      if (JSON.parse(localStorage.getItem('isListShuffled'))) {
        state.isListShuffled = JSON.parse(localStorage.getItem('isListShuffled'));
      }
      return state.isListShuffled;
    },

    emptySongs(state) {
      state.songs = [];
    },

    setSongs(state, payload) {
      state.songs.push(payload);
    },

    setManageSongs(state, val) {
      const song = {
        ...val.data(),
        docId: val.id,
      };
      state.manageSongs.push(song);
    },
  },

  actions: {
    async newSong({ commit, state, dispatch }, payload) {
      if (state.sound instanceof Howl) {
        state.sound.unload();
      }

      commit('newSong', payload);

      state.sound.play();

      state.sound.on('play', () => {
        requestAnimationFrame(() => {
          dispatch('progress');
        });
      });

      dispatch('saveCurrentSong');

      state.sound.on('end', () => {
        const currentSongIndex = state.currentPlaylist
          .findIndex((item) => item.docId === state.currentSong.docId);
        const nextSong = state.currentPlaylist.length > currentSongIndex + 1
          ? state.currentPlaylist[currentSongIndex + 1] : state.currentPlaylist[0];
        dispatch('newSong', nextSong);
      });
    },

    async toggleAudio({ state, dispatch }) {
      if (!state.sound.playing) {
        dispatch('newSong', state.currentSong);
      } else if (state.sound.playing()) {
        state.sound.pause();
      } else {
        state.sound.play();
      }
    },

    progress({ commit, state, dispatch }) {
      commit('updatePosition');

      if (state.sound.playing()) {
        requestAnimationFrame(() => {
          dispatch('progress');
        });
      }
    },

    updateSeek({ state, dispatch }, payload) {
      if (!(state.sound instanceof Howl)) {
        dispatch('newSong', state.currentSong);
        state.sound.pause();
      }

      const { x, width } = payload.currentTarget.getBoundingClientRect();
      const clickX = payload.clientX - x;
      const percentage = clickX / width;
      const seconds = state.sound.duration() * percentage;

      state.sound.seek(seconds);

      state.sound.on('seek', () => {
        dispatch('progress');
      });
    },

    updateVolume({ state }, payload) {
      state.currentVolume = +payload / 10;
      if (state.sound instanceof Howl) {
        state.sound.volume(+payload / 10);
      }
    },

    saveCurrentPlaylist({ state }) {
      if (state.currentPlaylist.length) {
        localStorage.setItem('currentPlaylist', JSON.stringify(state.currentPlaylist));
      }
    },

    saveCurrentSong({ state }) {
      if (state.currentSong && state.currentSong.uid) {
        localStorage.setItem('currentSong', JSON.stringify(state.currentSong));
      }
    },

    saveIsFiltered({ state }) {
      localStorage.setItem('isListFiltered', JSON.stringify(state.isListFiltered));
    },

    saveIsShuffled({ state }) {
      localStorage.setItem('isListShuffled', JSON.stringify(state.isListShuffled));
    },

    async getSongsCollection({ commit, state }) {
      commit('emptySongs');

      state.snapshots = await songsCollection
        .orderBy('modified_name')
        .get();

      state.snapshots.forEach((document) => {
        commit('setSongs', { docId: document.id, ...document.data() });
      });
    },

    async songsCollectionFromFirebase({ state, commit }) {
      state.manageSongs = [];

      const snapshot = await songsCollection.where('uid', '==', auth.currentUser.uid).get();
      await snapshot.forEach((document) => commit('setManageSongs', document));
    },

    updateSong({ state }, payload) {
      const songIndex = state.manageSongs.findIndex((item) => item.url === payload.url);

      state.manageSongs[songIndex].modified_name = payload.values.modified_name;
      state.manageSongs[songIndex].genre = payload.values.genre;
      state.manageSongs[songIndex].visibleToAll = payload.values.visibleToAll;
    },

    async removeSong({ state }, song) {
      state.manageSongs = state.manageSongs.filter((item) => item.docId !== song.docId);
    },

    async getComments({ state }, payload) {
      state.comments = [];
      const snapshots = await commentsCollection.where('sid', '==', payload).get();
      snapshots.forEach((doc) => {
        state.comments.push({
          docId: doc.id,
          ...doc.data(),
        });
      });
    },

    async addComment({ state }, payload) {
      const comment = {
        content: payload.values.comment,
        datePosted: new Date().toString(),
        sid: payload.id,
        name: auth.currentUser.displayName,
        uid: auth.currentUser.uid,
      };

      await commentsCollection.add(comment);

      await songsCollection.doc(payload.id).update({
        comment_count: payload.count,
      });
    },

    async deleteComment({ state }, payload) {
      await commentsCollection.doc(payload.comment.docId).delete();

      state.comments = state.comments.filter((item) => item.docId !== payload.comment.docId);

      await songsCollection.doc(payload.id).update({
        comment_count: payload.count,
      });
    },

    async getDocSnapshot({ state }, payload) {
      const res = await songsCollection.doc(payload.id).get();
      return res;
    },

    upload({state, commit}, e) {
      const files = e.dataTransfer ? [...e.dataTransfer.files] : [...e.target.files];

      files.forEach((file) => {
        if (file.type !== 'audio/mpeg') return;

        const storageRef = storage.ref();
        const songsRef = storageRef.child(`songs/${file.name}`);
        const task = songsRef.put(file);

        const uploadIndex = state.uploads.push({
          task,
          current_progress: 0,
          name: file.name,
          variant: 'bg-gray-400',
          icon: 'fas fa-spinner fa-spin',
          text_class: '',
          error_text: '',
        }) - 1;

        task.on('state_changed', (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          if (snapshot.totalBytes > maxFileSizeMb.value * 1024 * 1024) {
            state.uploads[uploadIndex].icon = 'fas fa-times';
            state.uploads[uploadIndex].text_class = 'font-medium text-gray-400';
            state.uploads[uploadIndex].error_text = 'File size is too large';
          } else {
            state.uploads[uploadIndex].current_progress = progress;
          }
        }, (error) => {
          if (state.uploads[uploadIndex].error_text) return;

          state.uploads[uploadIndex].variant = 'bg-red-400';
          state.uploads[uploadIndex].icon = 'fas fa-times';
          state.uploads[uploadIndex].text_class = 'text-red-400';

          console.error('error', error);
        }, async () => {
          const song = {
            uid: auth.currentUser.uid,
            display_name: auth.currentUser.displayName,
            original_name: task.snapshot.ref.name,
            modified_name: task.snapshot.ref.name,
            genre: '',
            comment_count: 0,
          };

          song.url = await task.snapshot.ref.getDownloadURL();
          const songRef = await songsCollection.add(song);
          const songSnapshot = await songRef.get();

          state.uploads[uploadIndex].variant = 'bg-gray-300';
          state.uploads[uploadIndex].icon = 'fas fa-check';
          state.uploads[uploadIndex].text_class = 'text-gray-300';

          commit('setManageSongs', songSnapshot)

          setTimeout(() => {
            state.uploads = [];
          }, 2000);
        });
      });
    },

    cancelUploads({state}) {
      state.uploads.forEach((upload) => {
        upload.task.cancel();
      });
    },

    async editComposition({state}, { song, values }) {
      await songsCollection.doc(song.docId).update(values);
    },

    async updateCompositionVisibility({state}, { song, isVisible }) {
      await songsCollection.doc(song.docId)
        .update({ ...song, visibleToAll: isVisible });
    },

    async deleteSong({state}, song) {
      await songsCollection.doc(song.docId).delete();

      const storageRef = storage.ref();
      const songRef = storageRef.child(`songs/${song.original_name}`);

      await songRef.delete();
    }
  },
};
