<template>
  <div class="text-white text-center font-bold p-4 mb-4"
    v-if="reg_show_alert" :class="reg_alert_variant">
    {{ $t(reg_alert_msg) }}
  </div>

  <vee-form :validation-schema="schema" ref="registerForm"
    @submit="register">

    <!-- Name -->
    <div class="mb-3">
      <label class="inline-block mb-2">
        {{ $t('global.name') }}*
      </label>
      <vee-field type="text" name="name"
        class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition
          duration-500 focus:outline-none focus:border-black rounded"
        :placeholder="$t('global.name_placeholder')" />
      <ErrorMessage class="text-red-400" name="name" />
    </div>

    <!-- Email -->
    <div class="mb-3">
      <label class="inline-block mb-2">
        {{ $t('global.email_placeholder') }}*
      </label>
      <vee-field type="email" name="email"
        class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition
          duration-500 focus:outline-none focus:border-black rounded"
         :placeholder="$t('global.email_placeholder')" />
      <ErrorMessage class="text-red-400" name="email" />
    </div>

    <!-- Age -->
    <div class="mb-3">
      <label class="inline-block mb-2">
        {{ $t('global.age') }}
      </label>
      <vee-field type="number" name="age"
        class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition
          duration-500 focus:outline-none focus:border-black rounded" />
      <ErrorMessage class="text-red-400" name="age" />
    </div>

    <!-- Password -->
    <div class="mb-3">
      <label class="inline-block mb-2">
        {{ $t('global.password') }}*
      </label>
      <vee-field name="password" :bails="false" v-slot="{ field, errors }">
        <input class="block w-full py-1.5 px-3 text-gray-800 border
          border-gray-300 transition duration-500 focus:outline-none
          focus:border-black rounded" type="password"
           :placeholder="$t('global.password_placeholder')"
           v-bind="field" />
        <div class="text-red-400" v-for="error in errors" :key="error">
          {{ error }}
        </div>
      </vee-field>
    </div>

    <!-- Confirm Password -->
    <div class="mb-3">
      <label class="inline-block mb-2">
        {{ $t('global.password') }}*
      </label>
      <vee-field type="password" name="confirm_password"
        class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition
          duration-500 focus:outline-none focus:border-black rounded"
         :placeholder="$t('global.confirm_password')" />
      <ErrorMessage class="text-red-400" name="confirm_password" />
    </div>

    <!-- TOS -->
    <div class="mb-3 pl-6">
      <vee-field type="checkbox" name="tos" value="1"
        class="w-4 h-4 float-left -ml-6 mt-1 rounded inline-block" />
      <i18n-t class="inline-block" keypath="register.accept" tag="label">
        <a href="#">{{ $t('register.TOS') }}*</a>
      </i18n-t>
      <ErrorMessage class="text-red-400 block" name="tos" />
    </div>
    <button type="submit" :disabled="reg_in_submission"
      class="block w-full bg-purple-500 text-white py-1.5 px-3 rounded transition
        hover:bg-purple-500">
      {{ $t('global.submit') }}
    </button>
  </vee-form>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, reactive, ref, watch } from "vue";

const schema = reactive({
  name: 'required|min:3|max:100|alpha_spaces',
  email: 'required|min:3|max:100|email',
  age: 'min_value:1|max_value:100',
  password: 'required|min:3|max:100',
  confirm_password: 'passwords_mismatch:@password',
  tos: 'tos',
})

const registerForm = ref(null)

const reg_in_submission = ref(false)
const reg_show_alert = ref(false)
const reg_alert_variant = ref('bg-gray-300')
const reg_alert_msg = ref('register.wait')

const store = useStore()

const authModalShow = computed(() => store.getters.authModalShow)

const register = async(values) => {
  reg_show_alert.value = true;
  reg_in_submission.value = true;
  reg_alert_variant.value = 'bg-gray-300';
  reg_alert_msg.value = 'register.wait';

  try {
    await store.dispatch('register', values);
  } catch (error) {
    console.log('error!', error);
    reg_in_submission.value = false;
    reg_alert_variant.value = 'bg-red-400';
    reg_alert_msg.value = 'register.error';
    return;
  }

  reg_alert_variant.value = 'bg-purple-400';
  reg_alert_msg.value = 'register.success';
  window.location.reload();
}

watch(() => authModalShow.value, (newVal) => {
  if (!newVal) {
    registerForm.value.resetForm();
  }
})
</script>
