<template>
  <div
    v-if="currentSong.modified_name"
    class="fixed bottom-0 left-0 bg-white p-5 pt-7 pb-2 text-left align-top w-full h-18 player"
  >
    <div class="relative">

      <!-- Play/Pause Button -->
      <div class="float-left w-7 h-7 leading-3">
        <button
          type="button"
          @click.prevent="toggleAudio"
          id="player-play-button"
          class="player__btn"
        >
          <i class="fa fa-pause text-gray-500 text-xl"
             :class="{ 'fa-play': !isPlaying, 'fa-pause': isPlaying }"
          ></i>
        </button>
      </div>

      <!-- Current Position -->
      <div
        class="float-left w-7 h-7 leading-3 text-gray-400 mt-0
        text-lg w-14 ml-5 mt-1 player__time"
      >
        <span class="player-currenttime">
          {{ seek }}
        </span>
      </div>

      <!-- Scrub -->
      <div class="float-left w-7 h-7 leading-3 ml-5 mt-0 player-scrub">
        <div
          v-if="currentSong.modified_name"
          class="absolute left-0 right-0 text-m text-center mx-auto player-song-info"
        >
          <span class="song-title">
            {{ currentSong.modified_name }}
          </span>
          <span class="song-artist">
            (Uploaded by {{ currentSong.display_name }})
          </span>
        </div>
        <span
          class="block w-full h-2 rounded m-1 mt-2 bg-gray-200 relative cursor-pointer player-progress"
          @click.prevent="updateSeek($event)"
        >
          <span class="absolute top-neg-10 text-purple-500 text-lg"
            :style="{ left: playerProgress }"
          >
            <i class="fas fa-circle"></i>
          </span>
          <span
            class="block h-2 rounded bg-gradient-to-r from-gray-300 to-purple-400"
            :style="{ width: playerProgress }">
          </span>
        </span>
      </div>

      <!-- Duration -->
      <div class="float-left w-7 h-7 leading-3 text-gray-400 mt-0 text-lg w-14 ml-8 mt-1">
        <span class="player-duration">
          {{ duration }}
        </span>
      </div>
      <button type="button" class="volume-btn float-right" @click="showVolumeRange">
        <i class="fas fa-volume-down text-gray-500 text-xl"></i>
      </button>
      <div
        v-show="isVolumeOpen"
        class="volume-range absolute"
      >
        <input
          class="rounded-lg overflow-hidden appearance-none bg-gray-400 h-3 w-100"
          type="range"
          @change="updateVolume($event.target.value)"
          min="0"
          max="10"
          value="4"
          step="1"
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onBeforeMount, ref } from "vue";

const isVolumeOpen = ref(false)

const store = useStore()

const isPlaying = computed(() => store.getters.isPlaying)
const currentSong = computed(() => store.getters.currentSong)
const seek = computed(() => store.getters.seek)
const duration = computed(() => store.getters.duration)
const playerProgress = computed(() => store.getters.playerProgress)

const toggleAudio = () => store.dispatch('toggleAudio')
const updateSeek = (value) => store.dispatch('updateSeek', value)
const updateVolume = (value) => store.dispatch('updateVolume', value)
const saveCurrentPlaylist = () => store.dispatch('saveCurrentPlaylist')
const getCurrentPlaylist = () => store.commit('getCurrentPlaylist')
const getCurrentSong = () => store.commit('getCurrentSong')
const showVolumeRange = () => isVolumeOpen.value = !isVolumeOpen.value

onBeforeMount(() => {
  saveCurrentPlaylist();
  getCurrentSong();
  getCurrentPlaylist();
})
</script>

<style lang="scss" scoped>
@import "src/assets/main.scss";

.player {
  box-shadow: $snowflake 1px 4px 50px;
  z-index: 3;

  @media (max-width: 600px) {
    padding: 30px 20px 10px 20px;
  }

  &__btn {
    @media (max-width: 600px) {
      i {
        font-size: 16px;
      }
    }
  }

  &__time {
    @media (max-width: 600px) {
      margin-left: 10px;
    }
  }

  .relative {
    @media (max-width: 600px) {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }
}

.player-currenttime, .player-duration {
  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.player-scrub, .player-song-info {
  width: calc(100% - 250px);

  @media (max-width: 600px) {
    width: 100%;
  }
}

.player-song-info {
  margin-top: -19px;
  max-width: 1000px;
  @include white-space;
  line-height: 20px;

  @media (max-width: 600px) {
    margin-top: -24px;
  }

}

.volume-btn {
  @media (max-width: 600px) {
    margin-left: 15px;
  }
}

.volume-range {
  right: -55px;
  bottom: 70px;
  transform: rotate(-90deg);

  @media (max-width: 600px) {
    bottom: 100px;
  }
}

input[type="range"] {
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  width: 15px;
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  cursor: pointer;
  background: $color-white;
  box-shadow: -405px 0 0 400px $purple-honeycreeper, 5px 0 0 400px $violet-heaven;
  border-radius: 50%;
}
</style>
