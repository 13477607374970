import { createStore } from 'vuex';
import auth from './modules/auth';
import player from './modules/player';

export default createStore({
  modules: {
    auth,
    player,
  },
  state: {
    language: 'en',
  },
  getters: {
    language: (state) => state.language,
  },
  mutations: {
    getLanguage(state) {
      if (JSON.parse(localStorage.getItem('language'))) {
        state.language = JSON.parse(localStorage.getItem('language'));
      }
    },
    setLanguage(state, payload) {
      state.language = payload;
      localStorage.setItem('language', JSON.stringify(state.language));
    },
  },
});
