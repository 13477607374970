<template>
  <header id="header" class="bg-gray-700">
    <nav class="container mx-auto flex justify-start items-center py-5 px-4">
      <router-link
        :to="{ name: 'home' }"
        class="text-white font-bold uppercase text-2xl mr-4 logo"
        exact-active-class="no-active"
      >
        Muz<span>X</span>
      </router-link>

      <div class="flex flex-grow items-center">
        <ul class="flex flex-row mt-1">
          <li>
            <router-link class="px-2 text-white" to="/about">
              {{ $t('header.about') }}
            </router-link>
          </li>
          <li v-if="!userLoggedIn">
            <a
              class="px-2 text-white toggleAuthModal-btn"
              href="#"
              @click.prevent="toggleAuthModal"
              data-test="loginBtn">
              {{ $t('global.login') }}
            </a>
          </li>
          <template v-else>
            <li>
              <router-link class="px-2 text-white" to="/manage">
                {{ $t('header.manage') }}
              </router-link>
            </li>
            <li>
              <a class="px-2 text-white signOut-btn" href="#"
                 @click.prevent="signOut">
                {{ $t('header.logout') }}
              </a>
            </li>
          </template>
        </ul>
        <ul class="flex flex-row mt-1 ml-auto">
          <li>
            <a href="#" class="px-2 text-white language-btn"
               @click.prevent="changeLocale"
               :title="$t('global.switch_language')"
            >
              {{ currentLocale }}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from 'vue-i18n';

const store = useStore();

const router = useRouter();
const route = useRoute();

const { locale } = useI18n();

const userLoggedIn = computed(() => store.getters.userLoggedIn)
const language = computed(() => store.getters.language)

const currentLocale = computed(() => locale.value === 'ru' ? 'Ru' : 'En')

const toggleAuthModal = () => store.commit('toggleAuthModal');

const signOut = () => {
  store.dispatch('signout', {
    router: router,
    route: route,
  });

  if (route.meta.requiresAuth) {
    router.push({ name: 'home' });
  }
}

const changeLocale = () => {
  locale.value = locale.value === 'ru' ? 'en' : 'ru';
  store.commit('setLanguage', locale.value);
}

onBeforeMount(() => {
  store.commit('getLanguage');
  locale.value = language.value;
})
</script>

<style lang="scss" scoped>
@import "src/assets/main";

.logo {
  span {
    background: -webkit-linear-gradient($polo-blue, $melrose);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
