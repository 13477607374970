export default {
  "global": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите имя"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возраст"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите почту"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите пароль"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
    "your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш аккаунт"])},
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так. Попробуйте повторить позже."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подождите! Информация обновляется."])},
    "switch_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключить на английский"])}
  },
  "home": {
    "listen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слушайте Вашу любимую музыку!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавляйте треки в плейлист и слушайте музыку от Ваших любимых исполнителей в режиме онлайн."])},
    "songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Композиции"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш плейлист пуст."])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете загрузить новые треки во вкладке Управление"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы применить фильтр, Вам нужно войти в систему и создать свой плейлист"])},
    "ABC_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать в алфавитном порядке"])},
    "random_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать в случайном порядке"])},
    "all_users_songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать доступные композиции всех пользователей"])},
    "my_songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать только мои композиции"])}
  },
  "about": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполняй форму и регистрируйся"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загружай музыку и редактируй"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставляй комментарии"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применяй фильтры и сортировку"])}
  },
  "song": {
    "comment_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Нет комментариев"]), _normalize([_interpolate(_named("n")), " комментарий"]), _normalize([_interpolate(_named("n")), " комментария"]), _normalize([_interpolate(_named("count")), " комментариев"])])},
    "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш комментарий..."])},
    "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначала новые"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначала старые"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название песни"])},
    "genre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жанр"])}
  },
  "register": {
    "accept": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Я принимаю ", _interpolate(_list(0))])},
    "TOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["условия использования Music"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подождите! Ваш аккаунт в процессе создания."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возникла непредвиденная ошибка. Попробуйте повторить позже."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово! Ваш аккаунт успешно создан."])}
  },
  "login": {
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подождите! Выполняется вход в аккаунт"])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверные данные."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово! Вход выполнен."])}
  },
  "manage": {
    "my_songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои песни"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь будут Ваши композиции :)"])},
    "delete_song": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить песню"])},
    "edit_song": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "make_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображать песню для других пользователей"])},
    "make_invisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть песню от других пользователей"])}
  },
  "upload": {
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])},
    "drop_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перетащите сюда свои файлы"])},
    "choose_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите файл"])}
  },
  "header": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Треки"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
  },
  "validation": {
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" необходимо заполнить"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["слишком короткое."])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["слишком длинное."])},
    "alpha_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["может содержать только символы алфавита и пробелы."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["должно содержать корректный email."])},
    "min_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ниже минимального."])},
    "max_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выше максимального."])},
    "excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нельзя использовать данное значение для поля"])},
    "country_excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В связи с ограничениями мы не принимаем пользователей из этой локации."])},
    "passwords_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают."])},
    "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам нужно принять Пользовательские Условия."])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["введено некорректно"])}
  }
}