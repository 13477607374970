import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBYj24Gka4v_9fAPubTKiEpp0rlEV5mINM',
  authDomain: 'music-app-5c514.firebaseapp.com',
  projectId: 'music-app-5c514',
  storageBucket: 'music-app-5c514.appspot.com',
  appId: '1:458023484160:web:39e8b0b548fb6ee57511e5',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

db.enablePersistence().catch((e) => {
  console.log(`Firebase persistence error ${e.code}`);
});

const usersCollection = db.collection('users');
const songsCollection = db.collection('songs');
const commentsCollection = db.collection('comments');

export {
  auth,
  db,
  usersCollection,
  songsCollection,
  commentsCollection,
  storage,
};
