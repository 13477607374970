<template>
  <div class="fixed z-50 inset-0 overflow-y-auto" id="modal"
    :class="{ hidden: !authModalShow }">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center
      sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-800 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden
        shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full auth-form">

        <div class="py-4 text-left px-6">
          <div class="flex justify-between items-center pb-4">
            <p class="text-2xl font-bold">
              {{ $t('global.your_account') }}
            </p>
            <div class="modal-close cursor-pointer z-50" @click.prevent.stop="toggleAuthModal">
              <i class="fas fa-times"></i>
            </div>
          </div>

          <!-- Tabs -->
          <ul class="flex flex-wrap mb-4">
            <li class="flex-auto text-center">
              <a class="block rounded py-2 px-4 transition"
                href="#" @click.prevent="tab = 'login'"
                :class="{
                  'hover:text-white text-white btn-auth': tab === 'login',
                  'hover:text-gray-400': tab === 'register'
                }">
                {{ $t('global.login') }}
              </a>
            </li>
            <li class="flex-auto text-center">
              <a class="block rounded py-2 px-4 transition"
                href="#" @click.prevent="tab = 'register'"
                :class="{
                  'hover:text-white text-white btn-auth': tab === 'register',
                  'hover:text-gray-400': tab === 'login'
                }">
                {{ $t('global.register') }}
              </a>
            </li>
          </ul>

          <AppLoginForm v-if="tab === 'login'" />
          <AppRegisterForm v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppLoginForm from './LoginForm.vue';
import AppRegisterForm from './RegisterForm.vue';
import { useStore } from 'vuex';
import { computed, ref } from "vue";

const store = useStore()
const tab = ref('login')

const toggleAuthModal = () => store.commit('toggleAuthModal');
const authModalShow = computed(() => store.getters.authModalShow);
</script>

<style lang="scss" scoped>
@import "src/assets/common";

.auth-form {
  @media (max-width: 640px) {
    width: 100%;
  }
}
.btn-auth {
  background: -webkit-linear-gradient(35deg, $color-auth, $color-auth-second);
}
</style>
