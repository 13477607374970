import {
  Form as VeeForm, Field as VeeField, defineRule, ErrorMessage, configure,
} from 'vee-validate';
import {
  required, min, max, alpha_spaces as alphaSpaces, email,
  min_value as minVal, max_value as maxVal, confirmed, not_one_of as excluded,
} from '@vee-validate/rules';
import i18n from './i18n';

const { t } = i18n.global;

export default {
  install(app) {
    app.component('VeeForm', VeeForm);
    app.component('VeeField', VeeField);
    app.component('ErrorMessage', ErrorMessage);

    defineRule('required', required);
    defineRule('tos', required);
    defineRule('min', min);
    defineRule('max', max);
    defineRule('alpha_spaces', alphaSpaces);
    defineRule('email', email);
    defineRule('min_value', minVal);
    defineRule('max_value', maxVal);
    defineRule('passwords_mismatch', confirmed);
    defineRule('excluded', excluded);

    configure({
      generateMessage: (ctx) => {
        const fieldNameUppercase = ctx.field.charAt(0).toUpperCase() + ctx.field.slice(1);
        const messages = {
          required: `${t('validation.field')} ${fieldNameUppercase} ${t('validation.required')}`,
          min: `${t('validation.field')} ${fieldNameUppercase} ${t('validation.min')}`,
          max: `${t('validation.field')} ${fieldNameUppercase} ${t('validation.max')}`,
          alpha_spaces: `${t('validation.field')} ${fieldNameUppercase} ${t('validation.alpha_spaces')}`,
          email: `${t('validation.field')} ${fieldNameUppercase} ${t('validation.email')}`,
          min_value: `${t('validation.field')} ${fieldNameUppercase} ${t('validation.min_value')}`,
          max_value: `${t('validation.field')} ${fieldNameUppercase} ${t('validation.max_value')}`,
          excluded: `${t('validation.excluded')} ${fieldNameUppercase}.`,
          passwords_mismatch: `${t('validation.passwords_mismatch')}`,
          tos: `${t('validation.tos')}`,
        };

        const message = messages[ctx.rule.name]
          ? messages[ctx.rule.name]
          : `${t('validation.field')} ${fieldNameUppercase} ${t('validation.invalid')}`;

        return message;
      },
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: false,
      validateOnModelUpdate: true,
    });
  },
};
