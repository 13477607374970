<template>
  <div class="text-white text-center font-bold p-4 mb-4"
     v-if="login_show_alert"
    :class="login_alert_variant">
    {{ $t(login_alert_msg) }}
  </div>

  <vee-form ref="loginForm" :validation-schema="loginSchema" data-test="loginForm"
    @submit="login">

    <!-- Email -->
    <div class="mb-3">
      <label class="inline-block mb-2">
        {{ $t('global.email') }}
      </label>
      <vee-field type="email" name="email"
        class="block w-full py-2 px-3 text-gray-800 border border-gray-300 transition
          duration-500 focus:outline-none focus:border-black rounded"
        :placeholder="$t('global.email_placeholder')" />
      <ErrorMessage class="text-red-600" name="email" />
    </div>

    <!-- Password -->
    <div class="mb-3">
      <label class="inline-block mb-2">
        {{ $t('global.password') }}
      </label>
      <vee-field type="password" name="password"
        class="block w-full py-2 px-3 text-gray-800 border border-gray-300 transition
          duration-500 focus:outline-none focus:border-black rounded"
         :placeholder="$t('global.password_placeholder')" />
      <ErrorMessage class="text-red-400" name="password" />
    </div>

    <button type="submit" :disabled="login_in_submission"
      class="block w-full bg-purple-500 text-white py-2 px-3 rounded transition
        hover:bg-purple-400">
      {{ $t('global.submit') }}
    </button>
  </vee-form>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, reactive, ref, watch } from "vue";

const loginSchema = reactive({
  email: 'required|email',
  password: 'required|min:3|max:32',
})

const login_in_submission = ref(false)
const login_show_alert = ref(false)
const login_alert_variant = ref('bg-gray-400')
const login_alert_msg = ref('login.wait')

const loginForm = ref(null);

const store = useStore()

const authModalShow = computed(() => store.getters.authModalShow)

const login = async(values) => {
  login_in_submission.value = true;
  login_show_alert.value = true;
  login_alert_variant.value = 'bg-gray-400';
  login_alert_msg.value = 'login.wait';

  try {
    await store.dispatch('login', values);
  } catch (error) {
    login_in_submission.value = false;
    login_alert_variant.value = 'bg-red-400';
    login_alert_msg.value = 'login.invalid';
    return;
  }

  login_alert_variant.value = 'bg-purple-400';
  login_alert_msg.value = 'login.success';
  window.location.reload();
}

watch(() => authModalShow.value, (newVal) => {
  if (!newVal) {
    loginForm.value.resetForm();
  }
})
</script>
