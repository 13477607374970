export default {
  "global": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Name"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Password"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Account"])},
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Try again later."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait! Updating info."])},
    "switch_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to Russian"])}
  },
  "home": {
    "listen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen to your Favorite Music!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tracks to the playlist and listen to music from your favorite artists online."])},
    "songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songs"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your playlist is empty."])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload new tracks in Manage page"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to log in and create your playlist in order to apply filter"])},
    "ABC_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in ABC order"])},
    "random_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in random order"])},
    "all_users_songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all visible users' songs"])},
    "my_songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only my songs"])}
  },
  "about": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the form and register"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload and manage your music"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave your comments"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter and sort your songs"])}
  },
  "song": {
    "comment_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No comments"]), _normalize(["1 Comment"]), _normalize([_interpolate(_named("count")), " Comments"])])},
    "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your comment here..."])},
    "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldest"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song Title"])},
    "genre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])}
  },
  "register": {
    "accept": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I accept Music's ", _interpolate(_list(0))])},
    "TOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait! Your account is being created."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Please try again later."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success! Your account has been created."])}
  },
  "login": {
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait! We are logging you in."])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid login details."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success! You are now logged in."])}
  },
  "manage": {
    "my_songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Songs"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here will be your songs :)"])},
    "delete_song": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete song"])},
    "edit_song": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit song details"])},
    "make_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make song visible for other users"])},
    "make_invisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make song invisible for other users"])}
  },
  "upload": {
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "drop_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop your files here"])},
    "choose_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the file"])}
  },
  "header": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "validation": {
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is required."])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is too short."])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is too long."])},
    "alpha_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["may only contain alphabetical characters and spaces."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be a valid email."])},
    "min_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is too low."])},
    "max_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is too high."])},
    "excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not allowed to use this value for the field"])},
    "country_excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to restrictions, we do not accept users from this location."])},
    "passwords_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords don't match."])},
    "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the Terms of Service."])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is invalid"])}
  }
}